import request from "@/utils/request";

// 创建账号
export function register(data) {
  return request({
    url: "/api/liaisonUser/register",
    method: "post",
    data,
  });
}

// 登录
export function login(data) {
  return request({
    url: "/api/liaisonUser/login",
    method: "post",
    data,
  });
}

// 登录
export function registerLogin(data) {
  return request({
    url: "/api/liaisonUser/registerLogin",
    method: "post",
    data,
  });
}

// 获取用户详情
export function getInfo(data) {
  return request({
    url: "/api/liaisonUser/getDetail",
    method: "post",
    data,
  });
}
// 获取酒店房型列表
export function getHotelRoomList(params) {
  return request({
    url: "/api/hotelInfo/getHotelRoomList",
    method: "get",
    params,
  });
}


//
export function addInsert(data) {
  return request({
    url: "/api/liaisonUserHotel/insert",
    method: "post",
    data,
  });
}

//
export function updateInsert(data) {
  return request({
    url: "/api/liaisonUserHotel/update",
    method: "post",
    data,
  });
}

//
export function delInsert(data) {
  return request({
    url: "/api/liaisonUserHotel/delete",
    method: "post",
    data,
  });
}

export function getRoomList(data) {
  return request({
    url: "/api/liaisonUserHotel/getList",
    method: "post",
    data,
  });
}





