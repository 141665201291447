import axios from "axios";
import { Message } from "element-ui";
import { Toast } from "vant";
import { _isMobile } from "@/utils/utils";
import store from "@/store";
import { getToken } from "@/utils/auth";

const lang = localStorage.getItem("lang") || "zh-CN";

// create an axios instance
const service = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "/api"
      : process.env.VUE_APP_BASE_API,
  timeout: 6000000, // request timeout
  withCredentials: true,
});
service.interceptors.request.use(
  (config) => {
    config.headers["language"] =
      lang == "zh-CN" ? "zh_CN" : lang == "en" ? "en_US" : "ru_RU";
    // do something before request is sent
    if (!config.params) {
      config.params = {};
    }
    if (store.getters.token) {
      config.headers["Authorization"] = getToken();
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // console.log(res);
    if (res.code === 0) {
      return { data: res.data, message: res.message };
    } else {
      // 根据设备类型进行不同提示
      if (_isMobile()) {
        Toast({
          message: res.message,
          type: "fail",
          forbidClick: true,
        });
      } else {
        Message.error(res.message);
      }
      // 当为20 重定向到登录页面
      if (res.code === 20) {
        store.dispatch("user/resetToken").then(() => {
          location.reload();
        });
      }
      return Promise.reject(response);
    }
  },
  (error) => {
    if (_isMobile()) {
      Toast({
        message: error.message,
        type: "fail",
        forbidClick: true,
      });
    } else {
      Message({
        message: error.message,
        type: "error",
        duration: 5 * 1000,
        showClose: true,
      });
    }
    return Promise.reject(error);
  }
);

export default service;
