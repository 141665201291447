export default function navigatorLanguage() {
  let lang = "zh-CN";
  try {
    let navigatorLanguage = (
      navigator.language || navigator.browserLanguage
    ).toLowerCase();
    // console.log("---浏览器语言----", navigatorLanguage);
    switch (navigatorLanguage) {
      case "ru":
        lang = "ru-RU";
        break;
    }
  } catch (e) {
    console.error("language error", e);
  }
  return lang;
}
