import { getInfo, login, logout, registerLogin } from "@/api/user";
import {
  getToken,
  getUserInfo,
  removeToken,
  removeUserInfo,
  setToken,
  setUserInfo,
} from "@/utils/auth";

const state = {
  token: getToken(),
  userInfo: getUserInfo(),
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then((response) => {
          const { data } = response;
          console.log('11111',data)
          commit("SET_TOKEN", data.token);
          commit("SET_USERINFO", data);
          setToken(data.token);
          setUserInfo(data);
          getInfo({ id: data.id }).then((t) => {
            localStorage.setItem('UserInfoData', JSON.stringify(t));
          })
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registerLogin({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      registerLogin(userInfo)
        .then((response) => {
          const { data } = response;
          commit("SET_TOKEN", data.token);
          commit("SET_USERINFO", data);
          setToken(data.token);
          setUserInfo(data);
          getInfo({ id: data.id }).then((t) => {
            localStorage.setItem('UserInfoData', JSON.stringify(t));
          })
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 刷新用户提交状态
  resetCompleteSubmit({ commit, state }) {
    return new Promise((resolve) => {
      commit("SET_USERINFO", { ...state.userInfo, isCompleteSubmit: 1 });
      setUserInfo({ ...state.userInfo, isCompleteSubmit: 1 });
      resolve();
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve) => {
      if (state.userInfo && state.userInfo.id) {
        getInfo({ id: state.userInfo.id }).then((response) => {
          const { data } = response;
          commit("SET_USERINFO", {
            ...state.userInfo,
            isCompleteSubmit: data.isCompleteSubmit,
          });
          setUserInfo({
            ...state.userInfo,
            isCompleteSubmit: data.isCompleteSubmit,
          });
          resolve();
        });
      } else {
        resolve();
      }
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit("SET_TOKEN", "");
          removeToken();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_USERINFO", "");
      removeToken();
      removeUserInfo();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
