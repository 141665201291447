import request from "@/utils/request";

// 获取单位/公司信息
export function getOrgInfos(data) {
  return request({
    url: "/api/orgInfo/getOrgInfos",
    method: "post",
    data,
  });
}

// 获取单位/公司信息是否已存在
export function getorgNameEn(data) {
  return request({
    url: "/api/orgInfo/getOrgInfos",
    method: "post",
    data,
  });
}

// 获取注册时间以及防疫上传状态
export function getTimeConfig(data) {
  return request({
    url: "/api/timeConfig/getTimeConfig",
    method: "post",
    data,
  });
}

// 获取验证码
export function getVeriryCode(data) {
  return request({
    url: "/api/common/getVerifyCode",
    method: "post",
    data,
  });
}
// 获取邮箱验证码
export function getEmailVerifyCode(data) {
  return request({
    url: "/api/common/getEmailVerifyCode",
    method: "post",
    data,
  });
}


// 根据账号获取六位数短信验证码
export function getVerifyCodeByUsername(data) {
  return request({
    url: "/api/common/getVerifyCodeByUsername",
    method: "post",
    data,
  });
}

// 验证验证码
export function verifyCode(data) {
  return request({
    url: "/api/common/verifyCode",
    method: "post",
    data,
  });
}

// 验证邮箱验证码
export function verifyEmailCode(data) {
  return request({
    url: "/api/common/verifyEmailCode",
    method: "post",
    data,
  });
}




// 重置密码
export function resetPassword(data) {
  return request({
    url: "/api/liaisonUser/changePwd",
    method: "post",
    data,
  });
}
export function getHotelList(params) {
  return request({
    url: 'api/hotelInfo/getHotelList',
    method: 'get',
    params
  })
}
