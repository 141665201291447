import router from "./router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/auth";
import store from "./store";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = [
  "/login",
  "/h5/register",
  "/welcome",
  "/resetPassword",
  "/home",
  "/news",
  "/newsDetail",
  "/overview",
  "/agenda",
  "/guests",
  "/meetingAchievements",
  "/host",
  "/achievementsDeatil"
]; // 白名单
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const hasToken = getToken();
  if (hasToken) {
    if (to.name === "login") {
      if (store.getters.userInfo.isCompleteSubmit == 2) {
        next({
          name:
            store.getters.userInfo.type == 1
              ? "fillMeeting"
              : "volunteerFillMeeting",
        });
      } else if (store.getters.userInfo.isCompleteSubmit == 1) {
        next({
          name: "personnelManagement",
        });
      }
      NProgress.done();
    } else {
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
  window.scrollTo(0, 0);
});
