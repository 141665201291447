import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./langs/en.js";
import zh from "./langs/zh.js";
import ru from "./langs/ru-RU.js";
import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import ruLocale from "element-ui/lib/locale/lang/ru-RU";
import navigatorLanguage from "@/navigatorLanguage";

Vue.use(VueI18n);
const language = localStorage.getItem("lang") || navigatorLanguage();
const i18n = new VueI18n({
  locale: language,
  messages: {
    "zh-CN": { ...zhLocale, ...zh },
    en: { ...enLocale, ...en },
    "ru-RU": { ...ruLocale, ...ru },
  },
});
export default i18n;
