import { getTimeConfig } from "@/api/index";

const state = {
  appConfig: {}, //项目公共的配置
  checkEpidemicDate: false, //防疫信息是否开启
  lang: localStorage.getItem("lang") || "zh-CN",
  guest:
    localStorage.getItem("guest") &&
    localStorage.getItem("guest") != "undefined"
      ? true
      : false,
};

const mutations = {
  SET_APPCONFIG: (state, data) => {
    state.appConfig = data;
  },
  SET_EPIDEMIC_DATE: (state, data) => {
    state.checkEpidemicDate = data.epidemicPreventionStatus == 2;
  },
  SET_GUEST: (state, data) => {
    state.guest = data;
  },
};

const actions = {
  // get user info
  getTimeConfig({ commit, state }) {
    return new Promise((resolve, reject) => {
      getTimeConfig(state.token)
        .then((response) => {
          const { data } = response;
          commit("SET_APPCONFIG", data);
          commit("SET_EPIDEMIC_DATE", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
