import Vue from "vue";
import VueRouter from "vue-router";
import H5Layout from "@/layout/h5/index";
import WebLayout from "@/layout/web/index";

Vue.use(VueRouter);

const routes = [
  // 官网部分
  {
    path: "/",
    component: WebLayout,
    redirect: "home",
    children: [
      {
        path: "home",
        name: "home",
        component: () =>
          import(
            /* webpackChunkName: "website" */ "../views/website/home/index.vue"
          ),
        meta: {
          description: "官网首页",
        },
      },
      {
        path: "news",
        name: "news",
        component: () =>
          import(
            /* webpackChunkName: "website" */ "../views/website/news/index.vue"
          ),
        meta: {
          description: "新闻动态页",
        },
      },
      {
        path: "newsDetail",
        name: "newsDetail",
        component: () =>
          import(
            /* webpackChunkName: "website" */ "../views/website/news/newDeatil.vue"
          ),
        meta: {
          description: "新闻动态详情页面",
        },
      },
      {
        path: "meetingAchievements",
        name: "meetingAchievements",
        component: () =>
          import(
            /* webpackChunkName: "website" */ "../views/website/meetingAchievements/index.vue"
          ),
        meta: {
          description: "会议成果页面，由后台添加，中英俄三语对应不同成果",
        },
      },
      {
        path: "overview",
        name: "overview",
        component: () =>
          import(
            /* webpackChunkName: "website" */ "../views/website/overview/index.vue"
          ),
        meta: {
          description: "会议概览页面",
        },
      },
      {
        path: "agenda",
        name: "agenda",
        component: () =>
          import(
            /* webpackChunkName: "website" */ "../views/website/agenda/index.vue"
          ),
        meta: {
          description: "会议议程页面",
        },
      },
      {
        path: "host",
        name: "host",
        component: () =>
            import(
                /* webpackChunkName: "website" */ "../views/website/host/index.vue"
                ),
        meta: {
          description: "举办地介绍页面",
        },
      },
      {
        path: "achievementsDeatil",
        name: "achievementsDeatil",
        component: () =>
            import(
                /* webpackChunkName: "website" */ "../views/website/achievementsDeatil/index.vue"
                ),
        meta: {
          description: "会议成果详情",
        },
      },
    ],
  },
  // 以下手机h5相关
  {
    path: "/qrCode",
    name: "qrCode",
    component: () =>
      import(/* webpackChunkName: "qrCode" */ "../views/h5/qrCode/index.vue"),
  },
  // 欢迎页面
  {
    path: "/welcome",
    name: "welcome",
    component: () =>
      import(/* webpackChunkName: "qrCode" */ "../views/h5/welcome/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "h5" */ "../views/h5/login/index.vue"),
    meta: {
      description: "登录页面，当携带?guest=1时，无需验证码登录",
    },
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () =>
      import(
        /* webpackChunkName: "h5" */ "../views/h5/resetPassword/index.vue"
      ),
    meta: {
      description: "重置密码页面",
    },
  },
  {
    path: "/personnelDetailh5",
    name: "personnelDetailh5",
    component: () =>
      import(
        /* webpackChunkName: "h5" */ "../views/h5/personnelManagement/personnelDetailh5.vue"
      ),
  },
  {
    path: "/h5",
    component: H5Layout,
    children: [
      {
        path: "register",
        name: "register",
        component: () =>
          import(/* webpackChunkName: "h5" */ "../views/h5/register/index.vue"),
      },
      {
        path: "addPerson",
        name: "addPerson",
        component: () =>
            import(/* webpackChunkName: "h5" */ "../views/h5/addPerson/index.vue"),
      },
      {
        path: "addPersonH5",
        name: "addPersonH5",
        component: () =>
            import(/* webpackChunkName: "h5" */ "../views/h5/addPersonH5/index.vue"),
      },
      {
        path: "addMeetPerson",
        name: "addMeetPerson",
        component: () =>
            import(/* webpackChunkName: "h5" */ "../views/h5/addMeetPerson/index.vue"),
      },
      {
        path: "addMeetPersonH5",
        name: "addMeetPersonH5",
        component: () =>
            import(/* webpackChunkName: "h5" */ "../views/h5/addMeetPersonH5/index.vue"),
      },
      {
        path: "hotelList",
        name: "hotelList",
        component: () =>
            import(/* webpackChunkName: "h5" */ "../views/h5/hotelList/index.vue"),
      },
      {
        path: "bookRoom",
        name: "bookRoom",
        component: () =>
            import(/* webpackChunkName: "h5" */ "../views/h5/bookRoom/index.vue"),
      },
      {
        path: "fillMeeting",
        name: "fillMeeting",
        component: () =>
          import(
            /* webpackChunkName: "h5" */ "../views/h5/fillMeeting/index.vue"
          ),
      },
      {
        path: "volunteerFillMeeting",
        name: "volunteerFillMeeting",
        component: () =>
          import(
            /* webpackChunkName: "h5" */ "../views/h5/volunteerFillMeeting/index.vue"
          ),
      },
      {
        path: "selectMeeting",
        name: "selectMeeting",
        component: () =>
          import(
            /* webpackChunkName: "h5" */ "../views/h5/selectMeeting/index.vue"
          ),
      },
      {
        path: "resultPage",
        name: "resultPage",
        component: () =>
          import(
            /* webpackChunkName: "h5" */ "../views/h5/resultPage/index.vue"
          ),
      },

      {
        path: "personnelManagement",
        name: "personnelManagement",
        component: () =>
          import(
            /* webpackChunkName: "h5" */ "../views/h5/personnelManagement/index.vue"
          ),
      },
      {
        path: "hotel",
        name: "hotel",
        component: () =>
          import(
            /* webpackChunkName: "h5" */ "../views/website/hotel/index.vue"
          ),
        meta: {
          description: "酒店信息页面",
        },
      },
      {
        path: "personnelDetail",
        name: "personnelDetail",
        component: () =>
          import(
            /* webpackChunkName: "h5" */ "../views/h5/personnelManagement/personnelDetail.vue"
          ),
      },
      {
        path: "noCommitPersonnelDetail",
        name: "noCommitPersonnelDetail",
        component: () =>
          import(
            /* webpackChunkName: "h5" */ "../views/h5/personnelManagement/personnelDetail.vue"
          ),
      },
      {
        path: "epidemicPrevention",
        name: "epidemicPrevention",
        component: () =>
          import(
            /* webpackChunkName: "h5" */ "../views/h5/personnelManagement/epidemicPrevention.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
