<template>
  <div :class="{ 'page-header-pc': !isMobile }" class="page-header df-c">
    <template v-if="!isMobile">
      <div class="page-header__left df-c">
        <img
          src="@/assets/images/logo.png"
          style="cursor: pointer"
          @click="$router.push('/')"
        />
        <div class="sys-name">{{ $t("common_sys_name") }}</div>
      </div>
      <div class="page-header__right df-c">
        <div class="df-c">
          <div
            v-for="(item, index) in menuList"
            :key="index"
            :class="{ 'menu-active': name == item.url }"
            :style="{
              'margin-right': index < menuList.length - 1 ? '24px' : '0px',
            }"
            class="menu"
            @click="goPage(item.url)"
          >
            {{ item.name }}
          </div>
          <el-divider direction="vertical"></el-divider>
        </div>
        <SwitchLanguage />
        <template v-if="isShowLogin">
          <el-divider direction="vertical"></el-divider>
          <UserInfo />
        </template>
      </div>
    </template>
    <template v-else>
        <div @click="openDrawer()">
          <svg-icon icon-class="icon_open"></svg-icon>
        </div>
      <div class="imagClass">
        <img
            height="28"
            src="@/assets/images/logo.png"
            style="cursor: pointer"
            width="28"
            @click="$router.push('/home')"
        />
        <SwitchLanguage :sizeFlag="true" />
      </div>

        <!--      <div>-->
        <!--&lt;!&ndash;        {{ $t("common_sys_name") }}&ndash;&gt;-->
        <!--        <div style="position: absolute;right: 135px;bottom: 20px;"> <SwitchLanguage :sizeFlag="true" /></div>-->
        <!--      </div>-->
        <UserInfo />
    </template>
    <Popup
      v-model="drawer"
      :style="{ height: '100%', width: '100%' }"
      position="left"
    >
      <div class="menu-popup">
        <div class="menu-popup__header df-c-b">
          <SwitchLanguage />
          <div class="close" @click="openDrawer()">
            <svg-icon icon-class="icon_close"></svg-icon>
          </div>
        </div>
        <div class="menu-popup__body">
          <div class="h5-menu">
            <div
              v-for="item in menuList"
              :key="item.url"
              :class="{ 'menu-item-active': name == item.url }"
              class="menu-item"
              @click="goPage(item.url)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import SwitchLanguage from "@/components/SwitchLanguage";
import UserInfo from "../h5/UserInfo.vue";
import { Popup } from "vant";

export default {
  name: "PageHeader",
  inject: ["isMobile"],
  data() {
    return {
      menuList: [
        {
          name: this.$t("website_general_01"),
          url: "home",
        },
        {
          name: this.$t("website_general_12"),
          url: "overview",
        },
        {
          name: this.$t("website_process_1"),
          url: "agenda",
        },
        // {
        //   name: this.$t("website_guests_1"),
        //   url: "guests",
        // },
        {
          name: this.$t("website_achievements_6"),
          url: "meetingAchievements",
        },
        {
          name: this.$t("website_general_2_1"),
          url: "host",
        },
      ],
      drawer: false,
    };
  },

  components: {
    SwitchLanguage,
    UserInfo,
    Popup,
  },
  computed: {
    isShowLogin() {
      return this.$route.name != "login";
    },
    name() {
      return this.$route.name;
    },
  },
  methods: {
    goPage(url) {
      this.drawer = false;
      console.log(url)
      if (url) {
        this.$router.push({
          name: url,
        });
      }
    },
    openDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  height: 55px;
  padding: 0 16px;
  background-color: #f7f8fa;
  justify-content: flex-end;
  border-bottom: 1px solid #e5e6eb;
  justify-content: space-between;

  :deep(.el-divider--vertical) {
    margin: 0 16px;
  }

  &__left {
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.page-header-pc {
  height: 80px;
  background-color: #fff;
  z-index: 10000;

  .page-header__left {
    img {
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }

    .sys-name {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      width: 500px;
      color: #0056a9;
    }
  }

  .page-header__right {
    .menu {
      // height: 24px;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      color: #1d2129;
      margin-right: 24px;
      cursor: pointer;
    }

    .menu-active {
      font-weight: 500;
      color: #165dff;
    }
  }
}

.menu-popup {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-image: url("../../assets/images/website/menu_bg.png");
  background-size: 100%;

  &__header {
    .close {
      :deep(.svg-icon) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__body {
    margin-top: 24px;

    .menu-item {
      width: fit-content;
      margin-bottom: 50px;
      height: 24px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.9);
    }

    .menu-item-active {
      position: relative;

      &::after {
        position: absolute;
        content: "";
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 4px;
        background: #0052d9;
      }
    }
  }
}
.imagClass {
  display: flex;
  justify-content: center;
  align-content: center;
  //text-align: center;
}
</style>
