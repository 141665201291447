<template>
  <div class="h5-layout">
    <header>
      <PageHeader />
    </header>
    <section class="main-content">
      <router-view></router-view>
    </section>
    <footer>
      <PageFooter v-if="!isMobile" />
    </footer>
  </div>
</template>

<script>
import PageFooter from "./PageFooter.vue";
import PageHeader from "./PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "H5Layout",
  data() {
    return {};
  },
  inject: ["isMobile"],
  provide() {
    return {
      userInfo: this.userInfo,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  components: {
    PageFooter,
    PageHeader,
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.h5-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .main-content {
    flex: 1;
    min-height: 0;
  }
}
</style>
