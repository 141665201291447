<template>
  <div :class="{ 'page-header-pc': !isMobile }" class="page-header df-c">
    <div class="page-header__left df-c">
      <img
        src="@/assets/images/logo.png"
        style="cursor: pointer"
        @click="$router.push('/')"
      />
      <div v-if="!isMobile" class="sys-name">
        {{ $t("common_sys_name")}}
      </div>
    </div>
    <div class="page-header__right df-c">
      <template v-if="isShowLogin">
        <img
            v-if="isMobile"
            height="19"
            src="@/assets/images/home.png"
            width="19"
            @click="goPage('home')"
        />
        <div v-else  @click="goPage('home')">{{$t('new_prop_14')}}</div>
        <el-divider direction="vertical"></el-divider>
      </template>
      <SwitchLanguage />
      <template v-if="isShowLogin">
        <el-divider direction="vertical"></el-divider>
        <UserInfo />
      </template>
    </div>
  </div>
</template>

<script>
import SwitchLanguage from "@/components/SwitchLanguage";
import UserInfo from "./UserInfo.vue";

export default {
  name: "PageHeader",
  inject: ["isMobile"],
  data() {
    return {};
  },
  components: {
    SwitchLanguage,
    UserInfo,
  },
  computed: {
    isShowLogin() {
      return this.$route.name != "login";
    },
  },
  methods: {
    goPage(url) {
      if (url) {
        this.$router.push({
          name: url,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  height: 55px;
  padding: 0 16px;
  background-color: #f7f8fa;
  justify-content: flex-end;
  border-bottom: 1px solid #e5e6eb;
  justify-content: space-between;

  :deep(.el-divider--vertical) {
    margin: 0 16px;
  }

  &__left {
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.page-header-pc {
  height: 80px;
  background-color: #fff;

  .page-header__left {
    img {
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }

    .sys-name {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      width: 500px;
      color: #0056a9;
    }
  }

  .page-header__right {
    .menu {
      height: 24px;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      color: #1d2129;
      margin-right: 24px;
      cursor: pointer;
    }

    .menu-active {
      font-weight: 500;
      color: #165dff;
    }
  }
}
</style>
