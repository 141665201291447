import Vue from "vue";
import App from "./App.vue";
import navigatorLanguage from "./navigatorLanguage";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "@/styles/index.scss";
import "./assets/icons/index";
// 路由鉴权
import "./permission";
// 引入svg
//引入svg组件
import SvgIcon from "./components/SvgIcon";
// 引入i18n
import i18n from "./locale";
// ElementUI国际化
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 引入wowjs
import wow from "wowjs";
import "wowjs/css/libs/animate.css";

if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang", navigatorLanguage());
}

Vue.component("svg-icon", SvgIcon);

// ElementUI.Select.computed.readonly = function () {
//   // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
//   const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));

//   return !(this.filterable || this.multiple || !isIE) && !this.visible;
// };

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});

Vue.prototype.$wow = wow;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
