<template>
  <div class="footer df-c">
    <div class="footer-box">
<!--      <el-popover placement="top" trigger="hover" width="278">-->
<!--        <div class="df-c">-->
<!--          <svg-icon icon-class="icon_emial"></svg-icon>-->
<!--          <span class="m-l-8 type">{{ $t("common_email") }}</span>-->
<!--        </div>-->
<!--        <div class="text m-t-8">ccacfiic@163.com</div>-->
<!--        <div class="text-line m-t-16" />-->
<!--        <div class="df-c m-t-16">-->
<!--          <svg-icon icon-class="icon_mobile"></svg-icon>-->
<!--          <span class="m-l-8 type">{{ $t("website_home_20") }}</span>-->
<!--        </div>-->
<!--        <div class="text-title m-t-12 text">{{ $t("new_prop_46") }}</div>-->
<!--        <div class="text m-t-8">-->
<!--          +86 15866876970（{{ $t("new_prop_46_1") }}）-->
<!--        </div>-->
<!--        <div class="text m-t-8">-->
<!--          +86 15953299625（{{ $t("new_prop_46_2") }}）-->
<!--        </div>-->
<!--        <div class="text m-t-8">+86 18661682701（{{ $t("new_prop_47") }}）</div>-->
<!--        <div class="text m-t-8">+86 15266233288（{{ $t("new_prop_48") }}）</div>-->
<!--        &lt;!&ndash; 分论坛联系人 &ndash;&gt;-->
<!--        <div class="text-title m-t-12 text">{{ $t("new_prop_48_1") }}</div>-->
<!--        <div class="text-title-desc m-t-8">{{ $t("new_prop_48_4") }}</div>-->
<!--        <div class="text">+86 17826028562（{{ $t("new_prop_48_5") }}）</div>-->
<!--        <div class="text-title-desc m-t-8">{{ $t("new_prop_48_2") }}</div>-->
<!--        <div class="text">+86 15318790924（{{ $t("new_prop_48_3") }}）</div>-->
<!--        <div class="text-title-desc m-t-8">{{ $t("new_prop_48_6") }}</div>-->
<!--        <div class="text">+86 13032200292（{{ $t("new_prop_48_7") }}）</div>-->
<!--        <div class="text-title m-t-12 text">{{ $t("common_support") }}</div>-->
<!--        <div class="text m-t-8">0532 - 68895902</div>-->
<!--        <span slot="reference" class="contart" style="cursor: pointer">-->
<!--          <svg-icon-->
<!--            v-if="mode == 'dark'"-->
<!--            icon-class="icon_footer_phone_white"-->
<!--          ></svg-icon>-->
<!--          <svg-icon v-else icon-class="icon_footer_phone_black"></svg-icon>-->
<!--          <span style="padding-left: 4px">{{ $t("new_prop_9") }}</span>-->
<!--        </span>-->
<!--      </el-popover>-->
<!--      <span class="line">|</span>-->
<!--      <span class="contart">Copyright ©️ 2022 青岛市发展和改革委员会</span>-->
<!--      <span class="line">|</span>-->
<!--      <span class="contart"-->
<!--        >{{ $t("common_support") }}：青岛征信服务有限公司</span-->
<!--      >-->
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  props: {
    mode: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {};
  },
  components: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.text-line {
  width: 100%;
  border: 1px solid #e5e6eb;
}

.text-title {
  // height: 24px;
  // font-weight: 500;
  // font-size: 16px;
  // line-height: 24px;
  // color: #1d2129 !important;
  position: relative;
  padding-left: 8px;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 3px;
    width: 2px;
    height: 14px;
    background-color: #606266;
    border-radius: 0.5px;
  }
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-l-8 {
  margin-left: 8px;
}

.footer {
  width: calc(100%);
  border-top: 1px solid #e5e6eb;
  background-color: #fff;
  box-sizing: border-box;
  padding-bottom: calc(constant(safe-area-inset-bottom)); /*兼容 IOS<11.2*/
  padding-bottom: calc(env(safe-area-inset-bottom)); /*兼容 IOS>11.2*/
  justify-content: center;

  .footer-box {
    width: fit-content;

    .line {
      margin: 0 16px;
      color: #e5e6eb;
    }

    .contart {
      height: 39px;
      line-height: 39px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4e5969;
    }

    .type {
      height: 22px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #4e5969;
    }

    .text {
      height: 22px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #4e5969;
    }
  }
}
</style>
