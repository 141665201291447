<template>
  <div class="pc-layout">
    <header class="pc-header">
      <PageHeader :showMenu="true" />
    </header>
    <section class="main-content">
      <router-view></router-view>
      <footer>
        <PageFooter />
      </footer>
    </section>
  </div>
</template>

<script>
import PageFooter from "./PageFooter.vue";
import PageHeader from "./PageHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "H5Layout",
  data() {
    return {};
  },
  provide() {
    return {
      userInfo: this.userInfo,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  components: {
    PageFooter,
    PageHeader,
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.pc-layout {
  .pc-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .main-content {
    padding-top: 81px;
  }
}

@media screen and (max-width: 1280px) {
  .pc-layout {
    .main-content {
      padding-top: 56px;
    }
  }
}
</style>
