<template>
  <div class="footer">
    <div class="footer__contact df">
      <!--      <div class="contact-left">-->
      <!--        <div class="df">-->
      <!--          <svg-icon icon-class="icon_emial"></svg-icon>-->
      <!--          <p style="margin-left: 8px; font-weight: 500">-->
      <!--            {{ $t("common_email") }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <p class="m-t-8">ccacfiic@163.com</p>-->
      <!--        <div class="df m-t-16 mobile-box">-->
      <!--          <svg-icon icon-class="icon_mobile"></svg-icon>-->
      <!--          <p style="margin-left: 8px; font-weight: 500">-->
      <!--            {{ $t("website_home_20") }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--        <p class="title m-t-8">{{ $t("new_prop_46") }}</p>-->
      <!--        <p class="m-t-8">-->
      <!--          <a href="tel:+86 18661682701"-->
      <!--            >+86 18661682701（{{ $t("new_prop_47") }}）</a-->
      <!--          >-->
      <!--        </p>-->
      <!--        <p class="m-t-8">-->
      <!--          <a href="tel:+86 13220889890"-->
      <!--            >+86 13220889890（{{ $t("new_prop_48") }}）</a-->
      <!--          >-->
      <!--        </p>-->
      <!--        <p class="title m-t-8">{{ $t("common_support") }}</p>-->
      <!--        <p class="m-t-8">-->
      <!--          <a href="tel:0532 - 68895902">0532 - 68895902</a>-->
      <!--        </p>-->
      <!--      </div>-->
      <div class="contact-right">
        <el-row :gutter="16" style="text-align: center">
          <el-col :span="6">
            <div class="right-title">{{ $t("website_home_24") }}</div>
            <div
              class="right-second-title"
            >
<!--              @click="goPage('https://www.ndrc.gov.cn')"-->
              {{ $t("website_home_27") }}
            </div>
            <div
              class="right-second-title"

            >
<!--              @click="goPage('http://www.shandong.gov.cn')"-->
              {{ $t("website_home_28") }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="right-title">{{ $t("website_home_25") }}</div>
            <div
                class="right-second-title"
            >
<!--              @click="goPage('http://www.qingdao.gov.cn')"-->
              {{ $t("website_home_29") }}
            </div>
            <div
                class="right-second-title"
            >
              {{ $t("website_home_29_1") }}
            </div>
            <div
                class="right-second-title"
            >
<!--              @click="goPage('http://fgw.shandong.gov.cn')"-->
              {{ $t("website_home_30") }}
            </div>
            <div
                class="right-second-title"
            >
              {{ $t("website_home_30_1") }}
            </div>

          </el-col>
<!--          <el-col :span="6">-->
<!--            <div class="right-title">{{ $t("website_home_26") }}</div>-->
<!--            <div-->
<!--              class="right-second-title"-->
<!--              @click="goPage('https://www.hisense.com')"-->
<!--            >-->
<!--              {{ $t("website_home_26_2") }}-->
<!--            </div>-->
<!--            <div-->
<!--              class="right-second-title"-->
<!--              @click="goPage('https://www.qingdaonengyuan.com')"-->
<!--            >-->
<!--              {{ $t("website_home_26_3") }}-->
<!--            </div>-->
<!--            <div-->
<!--              class="right-second-title"-->
<!--              @click="goPage('http://www.sd-port.com')"-->
<!--            >-->
<!--              {{ $t("website_home_26_4") }}-->
<!--            </div>-->
<!--            <div-->
<!--              class="right-second-title"-->
<!--              @click="goPage('http://www.qdwanlin.com')"-->
<!--            >-->
<!--              {{ $t("website_home_26_1") }}-->
<!--            </div>-->
<!--          </el-col>-->
          <el-col :span="6" v-if="lang == 'zh-CN'">
            <div class="right-title">{{$t('new_prop_9')}}</div>
            <div class="right-second-title">技术咨询电话</div>
            <div class="right-second-title">17660611101</div>
            <div class="right-second-title">注册咨询电话</div>
            <div class="right-second-title">0532 51915030</div>
            <div class="right-second-title">0532 51915031</div>
            <div class="right-second-title">51915030</div>
          </el-col>
          <el-col v-if="userInfo && userInfo.encryName" :span="6">
            <div class="right-title">{{ $t("website_home_wentifankui") }}</div>
            <div class="right-second-title">527047006@qq.com</div>
          </el-col>
        </el-row>
      </div>
    </div>
<!--    <div class="footer__record">-->
<!--      <template v-if="!isMobile">-->
<!--        <el-popover placement="top" trigger="hover" width="278">-->
<!--          <div class="df-c">-->
<!--            <svg-icon icon-class="icon_emial"></svg-icon>-->
<!--            <span class="m-l-8 type">{{ $t("common_email") }}</span>-->
<!--          </div>-->
<!--          <div class="text m-t-8">ccacfiic@163.com</div>-->
<!--          <div class="text-line m-t-16" />-->
<!--          <div class="df-c m-t-16">-->
<!--            <svg-icon icon-class="icon_mobile"></svg-icon>-->
<!--            <span class="m-l-8 type">{{ $t("website_home_20") }}</span>-->
<!--          </div>-->
<!--          <div class="text-title m-t-12 text">{{ $t("new_prop_46") }}</div>-->
<!--          <div class="text m-t-8">-->
<!--            +86 15866876970（{{ $t("new_prop_46_1") }}）-->
<!--          </div>-->
<!--          <div class="text m-t-8">-->
<!--            +86 15953299625（{{ $t("new_prop_46_2") }}）-->
<!--          </div>-->
<!--          <div class="text m-t-8">-->
<!--            +86 18661682701（{{ $t("new_prop_47") }}）-->
<!--          </div>-->
<!--          <div class="text m-t-8">-->
<!--            +86 15266233288（{{ $t("new_prop_48") }}）-->
<!--          </div>-->
<!--          &lt;!&ndash; 分论坛联系人 &ndash;&gt;-->
<!--          <div class="text-title m-t-12 text">{{ $t("new_prop_48_1") }}</div>-->
<!--          <div class="text-title-desc m-t-8">{{ $t("new_prop_48_4") }}</div>-->
<!--          <div class="text">+86 17826028562（{{ $t("new_prop_48_5") }}）</div>-->
<!--          <div class="text-title-desc m-t-8">{{ $t("new_prop_48_2") }}</div>-->
<!--          <div class="text">+86 15318790924（{{ $t("new_prop_48_3") }}）</div>-->
<!--          <div class="text-title-desc m-t-8">{{ $t("new_prop_48_6") }}</div>-->
<!--          <div class="text">+86 13032200292（{{ $t("new_prop_48_7") }}）</div>-->
<!--          <div class="text-title m-t-12 text">{{ $t("common_support") }}</div>-->
<!--          <div class="text m-t-8">0532 - 68895902</div>-->
<!--          <span-->
<!--            slot="reference"-->
<!--            class="contart"-->
<!--            style="cursor: pointer; font-size: 16px"-->
<!--          >-->
<!--            <svg-icon icon-class="icon_footer_message"></svg-icon>-->
<!--            <span-->
<!--              style="padding-left: 8px; font-weight: 500; font-size: 16px"-->
<!--              >{{ $t("new_prop_9") }}</span-->
<!--            >-->
<!--          </span>-->
<!--        </el-popover>-->
<!--        <span class="line" style="margin: 0 16px">|</span>-->
<!--      </template>-->
<!--      <span>Copyright ©️ 2022 青岛市发展和改革委员会</span>-->
<!--      <span class="line" style="margin: 0 16px">|</span>-->
<!--      <span>{{ $t("common_support") }}：青岛征信服务有限公司</span>-->
<!--      <span class="line" style="margin: 0 16px">|</span>-->
<!--      <span-->
<!--        style="cursor: pointer"-->
<!--        @click="goPage('https://beian.miit.gov.cn/')"-->
<!--        >鲁ICP备12005823号-4</span-->
<!--      >-->
<!--      &lt;!&ndash; <svg-icon icon-class="icon_beian" style="margin-left: 16px"></svg-icon>-->
<!--      <span>鲁公网安备 37021302001049号</span> &ndash;&gt;-->
<!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PageFooter",
  inject: ["isMobile"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userInfo"]),
    lang() {
      return localStorage.getItem("lang");
    },
  },
  components: {},
  watch: {},
  mounted() {
    console.log("isMobile", this.isMobile);
  },
  methods: {
    goPage(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.text-line {
  width: 100%;
  border: 1px solid #e5e6eb;
}

.text-title {
  // height: 24px;
  // font-weight: 500;
  // font-size: 16px;
  // line-height: 24px;
  // color: #1d2129 !important;
  position: relative;
  padding-left: 8px;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 3px;
    width: 2px;
    height: 14px;
    background-color: #606266;
    border-radius: 0.5px;
  }
}

a {
  color: #fff;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-12 {
  margin-top: 12px;
}

.footer {
  background: #2b3033;

  &__contact {
    padding: 32px 80px;

    .contact-left {
      padding-right: 56px;
      border-right: 1px solid #86909c;
      max-width: 448px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;

        span {
          &:nth-child(2) {
            margin-left: 8px;
          }
        }
      }

      .title {
        position: relative;
        padding-left: 9px;
        font-weight: 500;

        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: 4px;
          width: 2px;
          height: 16px;
          background-color: #fff;
          border-radius: 1px;
        }
      }

      :deep(.svg-icon) {
        width: 24px;
        height: 24px;
      }
    }

    .contact-right {
      flex: 1;
      //padding-left: 56px;
      ::v-deep .el-row {
        display: flex;
        justify-content: center;
      }
      .right-title {
        text-align: center;
        // height: 32px;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #ffffff;
        margin-bottom: 16px;
      }

      .right-second-title {
        cursor: pointer;
        margin-top: 8px;
        text-align: center;
        font-weight: 500;
        // height: 24px;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }

  &__record {
    border-top: 1px solid #f2f3f5;
    padding: 16px 0;
    text-align: center;

    span {
      // height: 22px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 1280px) {
  .footer {
    .footer__contact {
      display: block;
      padding: 16px 16px 0;

      .contact-left {
        padding-right: 0;
        padding-bottom: 16px;
        text-align: center;
        border-right: 0;

        p {
          // height: 22px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;

          span {
            display: block;
            margin-top: 8px;
          }
        }

        .title {
          position: unset;
        }

        .df {
          width: fit-content;
          margin: 16px auto 0;
        }

        border-bottom: 1px solid #86909c;
      }

      .contact-right {
        padding-left: 0px;
        padding-bottom: 20px;

        .el-row {
          margin: 0;
        }

        .el-col-8 {
          width: 100%;
          padding: 0 !important;
          margin: 0;
        }

        .right-title {
          // height: 28px;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 0;
          margin-top: 0px;
        }

        .right-second-title {
          // height: 22px;
          font-weight: 500;
          font-size: 6px;
          line-height: 10px;
          text-align: center;
          color: #ffffff;
          //padding-bottom: 10px;
        }
      }
    }

    &__record {
      border-top: 1px solid #f2f3f5;
      margin-top: 32px;

      span {
        display: block;
        // height: 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      .line {
        display: none;
      }
    }
  }
}
</style>
