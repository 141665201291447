<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// 判断设备类型
import { _isMobile } from "@/utils/utils";

export default {
  name: "App",
  provide() {
    return {
      isMobile: this.isMobile,
    };
  },
  data() {
    return {
      isMobile: _isMobile(),
    };
  },
  methods: {
    getTimeConfig() {
      this.$store.dispatch("app/getTimeConfig", {});
      this.$store.dispatch("user/getInfo", {});
    },
  },
  created() {
    this.getTimeConfig();
    // this.getInfo();
    setInterval(() => {
      this.getTimeConfig();
    }, 1000 * 60 * 4);
  },
};
</script>
<style lang="scss">
html {
  font-family: "PingFang SC";
}

.el-select-dropdown {
  max-width: calc(100% - 32px);
}

.el-scrollbar .el-scrollbar__bar {
  opacity: 1 !important;
}

.van-tabs__line {
  background-color: #0052d9 !important;
}

.van-cascader__option--selected {
  color: #0052d9 !important;
}
</style>
