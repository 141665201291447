<template>
  <div>
    <span v-if="sizeFlag" class="el-dropdown-link01 df-c">
      <span @click="handleCommand('zh-CN')">中文</span>
      <img src="@/assets/images/lang.png" />
      <span @click="handleCommand('en')">English</span>
    </span>
    <span v-else class="el-dropdown-link df-c">
      <span @click="handleCommand('zh-CN')">中文</span>
      <img src="@/assets/images/lang.png" />
      <span @click="handleCommand('en')">English</span>
    </span>
  </div>
<!--  <el-dropdown placement="bottom" trigger="click" @command="handleCommand">-->
<!--    <span class="el-dropdown-link df-c">-->
<!--      <img src="@/assets/images/lang.png" />-->
<!--      <span>{{ lang == "zh-CN" ? "简体中文" : "English" }}</span>-->
<!--      <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--    </span>-->
<!--    <el-dropdown-menu slot="dropdown">-->
<!--      <el-dropdown-item command="zh-CN">{{ $t("lang") }}</el-dropdown-item>-->
<!--      &lt;!&ndash;      <el-dropdown-item command="ru-RU">{{ $t("lang_ru") }}</el-dropdown-item>&ndash;&gt;-->
<!--      <el-dropdown-item command="en">{{ $t("lang_en") }}</el-dropdown-item>-->
<!--    </el-dropdown-menu>-->
<!--  </el-dropdown>-->
</template>

<script>
export default {
  name: "switch-language",
  data() {
    return {};
  },
  props:[
    'sizeFlag'
  ],
  components: {},
  watch: {},
  mounted() {},
  computed: {
    lang() {
      return localStorage.getItem("lang");
    },
  },
  methods: {
    handleCommand(lang) {
      if (lang == this.lang) {
        return;
      }
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
  font-size: 17px;

  img {
    display: block;
    margin: 0 7px 0 7px;
    width: 12px;
    height: 12px;
  }
}
.el-dropdown-link01{
  height: 28px;
  cursor: pointer;
  font-size: 10px;

  img {
    display: block;
    margin: 0px 7px 0px 7px;
    width: 10px;
    height: 10px;
  }
}
</style>
