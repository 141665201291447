<template>
  <div class="UserInfo">
    <template v-if="isMobile">
      <el-popover
        v-model="showPopover"
        :visible-arrow="false"
        placement="bottom"
        popper-class="user-info-popper"
        trigger="click"
        width="100%"
      >
        <template v-if="!(userInfo && userInfo.encryName)">
          <div class="user-info-popper-action" @click="goPage()">
            <svg-icon icon-class="icon_login"></svg-icon>
            <span>{{ $t("common_login") }}</span>
          </div>
          <div class="user-info-popper-action" @click="goPage1()">
            <svg-icon icon-class="icon_open"></svg-icon>
            <span>{{ $t("common_register") }}</span>
          </div>
<!--          <div class="user-info-popper-action" @click="contactUs()">-->
<!--            <svg-icon icon-class="icon_footer_phone_black"></svg-icon>-->
<!--            <span>{{ $t("new_prop_9") }}</span>-->
<!--          </div>-->
        </template>
        <template v-else>
          <div class="user-info-popper-company">
            <p class="user-name">{{ userInfo.encryName }}</p>
            <p class="user-company">{{ userInfo.orgName }}</p>
          </div>
          <div
            class="user-info-popper-action"
            @click="handleCommand('personal')"
          >
            <svg-icon icon-class="icon_user"></svg-icon>
            <span>{{ $t("new_prop_37") }}</span>
          </div>
          <div
            class="user-info-popper-action"
            @click="handleCommand('changePas')"
          >
            <svg-icon icon-class="icon_lock"></svg-icon>
            <span>{{ $t("new_prop_38") }}</span>
          </div>
<!--          <div class="user-info-popper-action" @click="contactUs()">-->
<!--            <svg-icon icon-class="icon_footer_phone_black"></svg-icon>-->
<!--            <span>{{ $t("new_prop_9") }}</span>-->
<!--          </div>-->
          <div class="user-info-popper-action" @click="handleCommand('logout')">
            <svg-icon icon-class="icon_login"></svg-icon>
            <span>{{ $t("new_prop_39") }}</span>
          </div>
        </template>

        <div slot="reference">
          <i
            v-if="!(userInfo && userInfo.encryName)"
            class="el-icon-more"
            style="color: #4e5969; font-size: 14px"
          ></i>
          <div v-else class="avator df-c">
            <img height="14" src="@/assets/images/user.png" width="14" />
          </div>
        </div>
      </el-popover>

      <Popup
        v-model="popupVisible"
        :style="{ height: '90%' }"
        position="bottom"
        round
      >
        <div class="popup">
          <div class="popup__header">
            <div @click="popupVisible = false">
              <svg-icon icon-class="icon_close"></svg-icon>
            </div>
          </div>
          <div class="popup__body" style="white-space: pre-wrap">
            <div class="title">{{ $t("new_prop_9") }}</div>
            <div class="df-c m-t-16">
              <svg-icon icon-class="icon_emial"></svg-icon>
              <span class="m-l-8 type">{{ $t("common_email") }}</span>
            </div>
            <div class="text m-t-8">ccacfiic@163.com</div>
            <div class="text-line m-t-16" />
            <div class="df-c m-t-16">
              <svg-icon icon-class="icon_mobile"></svg-icon>
              <span class="m-l-8 type">{{ $t("website_home_20") }}</span>
            </div>
            <div class="text-title m-t-12 text">{{ $t("new_prop_46") }}</div>
            <div class="text m-t-8">
              <a href="tel:+86 15866876970"
                >+86 15866876970（{{ $t("new_prop_46_1") }}）
              </a>
            </div>
            <div class="text m-t-8">
              <a href="tel:+86 15953299625"
                >+86 15953299625（{{ $t("new_prop_46_2") }}）
              </a>
            </div>
            <div class="text m-t-8">
              <a href="tel:+86 18661682701"
                >+86 18661682701（{{ $t("new_prop_47") }}）
              </a>
            </div>
            <div class="text m-t-8">
              <a href="tel:+86 15266233288"
                >+86 15266233288（{{ $t("new_prop_48") }}）
              </a>
            </div>
            <!-- 分论坛联系人 -->
            <div class="text-title m-t-12 text">{{ $t("new_prop_48_1") }}</div>
            <div class="text-title-desc m-t-8">{{ $t("new_prop_48_4") }}</div>
            <div class="text">
              <a href="tel:+86 17826028562"
                >+86 17826028562（{{ $t("new_prop_48_5") }}）
              </a>
            </div>
            <div class="text-title-desc m-t-8">{{ $t("new_prop_48_2") }}</div>
            <div class="text">
              <a href="tel:+86 15318790924"
                >+86 15318790924（{{ $t("new_prop_48_3") }}）
              </a>
            </div>
            <div class="text-title-desc m-t-8">{{ $t("new_prop_48_6") }}</div>
            <div class="text">
              <a href="tel:+86 13032200292"
                >+86 13032200292（{{ $t("new_prop_48_7") }}）
              </a>
            </div>
            <div class="text-title m-t-12 text">{{ $t("common_support") }}</div>
            <div class="text m-t-8">
              <a href="tel:0532 - 68895902">0532 - 68895902</a>
            </div>
          </div>
<!--          <div class="popup__footer">-->
<!--            <span class="contart"-->
<!--              >Copyright ©️ 2022 青岛市发展和改革委员会</span-->
<!--            >-->
<!--            <span class="contart m-t-8"-->
<!--              >{{ $t("common_support") }}：青岛征信服务有限公司</span-->
<!--            >-->
<!--            <span class="contart m-t-8">鲁ICP备12005823号-4</span>-->
<!--          </div>-->
        </div>
      </Popup>
    </template>

    <template v-else>
      <template v-if="userInfo && userInfo.encryName">
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="df-c">
            <div class="avator df-c">
              <img height="14" src="@/assets/images/user.png" width="14" />
            </div>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personal"
              >{{ $t("new_prop_37") }}
            </el-dropdown-item>
<!--            <el-dropdown-item command="changePas"-->
<!--              >{{ $t("new_prop_38") }}-->
<!--            </el-dropdown-item>-->
            <el-dropdown-item command="logout"
              >{{ $t("new_prop_39") }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <div style="display: flex; align-items: center" v-else>
        <div class="login-text" style="margin-right: 4px" @click="goPage()">
          {{ $t("login") }}
          /
        </div>
        <div class="login-text" @click="goPage1()">
          {{ $t("register") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Popup } from "vant";

export default {
  name: "UserInfo",
  inject: ["isMobile"],
  data() {
    return {
      showPopover: false,
      popupVisible: false,
    };
  },
  components: {
    Popup,
  },
  computed: {
    ...mapGetters(["userInfo", "guest"]),
  },
  methods: {
    goPage() {
      this.$router.push({
        name: "login",
      });
    },
    goPage1() {
      let Url = window.location.href.split('/#/')
     console.log(window.location.href)
      console.log(Url)
      if(Url.length > 1){
        if(Url[1].indexOf('home') > -1){
          document.getElementById("elementId").scrollIntoView();
        }else {
          this.$router.push({
            name: "home",
            query : {
              id:'elementId'
            }
          });
        }
      }
      // document.getElementById("elementId").scrollIntoView();
      // this.$router.push({
      //   name: "home",
      //   query : {
      //     id:'elementId'
      //   }
      // });
      // document.getElementById("elementId").scrollIntoView();
      // this.$router.push({
      //   name: "register",
      //   query: {
      //     type: 1,
      //   },
      // });
    },
    contactUs() {
      this.showPopover = false;
      this.popupVisible = true;
    },
    handleCommand(type) {
      this.showPopover = false;
      if (type === "logout") {
        var params = {};
        console.log(this.guest);
        if (this.guest) {
          params.guest = 1;
        }
        this.$store.dispatch("user/resetToken").then(() => {
          this.$router.push({
            name: "login",
            query: params,
          });
        });
      } else if (type == "personal") {
        if (this.userInfo.isCompleteSubmit == 2) {
          this.$router.push({
            name:
              this.userInfo.type == 1 ? "fillMeeting" : "volunteerFillMeeting",
            query: {
              para: Date.now(),
            },
          });
        } else {
          this.$router.push({
            name: "personnelManagement",
            query: {
              para: Date.now(),
            },
          });
        }
      } else if (type == "changePas") {
        this.$router.push({
          name: "resetPassword",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-line {
  width: 100%;
  border: 1px solid #e5e6eb;
}

.avator {
  width: 32px;
  height: 32px;
  background: #e8f3ff;
  border-radius: 16px;
  justify-content: center;
}

.login-text {
  cursor: pointer;
  /* 登录 */
  height: 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #165dff;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.popup {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    height: 52px;
    line-height: 52px;
    border-bottom: 1px solid #ebebeb;
    padding: 0 16px;

    :deep(.svg-icon) {
      width: 20px;
      height: 20px;
    }
  }

  &__body {
    flex: 1;
    min-height: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #1d2129;
    }

    .m-t-8 {
      margin-top: 8px;
    }

    .m-t-12 {
      margin-top: 12px;
    }

    .m-l-8 {
      margin-left: 8px;
    }

    .type {
      height: 22px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #1d2129;
    }

    .text {
      min-height: 22px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #1d2129;

      a {
        font-size: 14px;
        color: #1d2129;
      }
    }

    .text-title {
      height: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1d2129;
      position: relative;
      padding-left: 10px;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 4px;
        width: 2px;
        height: 16px;
        background-color: #4e5969;
      }
    }

    .text-title-desc {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #1d2129;
    }
  }

  &__footer {
    padding: 12px 16px;
    background: #f7f8fa;
    text-align: center;

    span {
      display: block;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #1d2129;
    }
  }
}
</style>
<style lang="scss">
.user-info-popper {
  border: 0;
  box-shadow: unset;
  margin-top: 0px !important;
  top: 55px !important;
  left: 0 !important;
  width: 100% !important;
  padding: 0 16px !important;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) !important;
  // border-radius: 0px 0px 8px 8px !important;
  .user-info-popper-action {
    padding: 16px 0;
    border-bottom: 0.5px solid #e5e6eb;
    text-align: center;

    span {
      margin-left: 12px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .user-info-popper-company {
    padding: 16px 0;
    position: relative;

    .user-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #1d2129;
    }

    .user-company {
      margin-top: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #4e5969;
    }

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: -16px;
      width: calc(100% + 32px);
      border-bottom: 1px solid #e5e6eb;
    }
  }
}
</style>
